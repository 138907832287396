import CenterWrapper from "../design-system/CenterWrapper";

const FeatureFallback = () => {
  return (
    <CenterWrapper>
      <div className="flex items-center w-full h-full justify-center">
        <div className="relative">
          <img src="/assets/image/new-feature-state.svg" alt="new feature" />
          <div className="absolute right-0 top-[30%] max-w-[29rem]">
            <p className="typography-h1-bold text-n-700">
              Exciting Things are on the Way!
            </p>
            <p className="typography-subtitle text-n-700">
              Hey there!👋 We're excited to let you know that we're working hard
              on a new feature that we think you'll love. It's still in the
              works, but we'll keep you updated on its progress. Stay tuned!
            </p>
          </div>
        </div>
      </div>
    </CenterWrapper>
  );
};

export default FeatureFallback;
