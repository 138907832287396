import { shallow } from "zustand/shallow";
import { lazyWithRetry } from "./helper/lazyWithRetry";
import useMenu from "./hooks/useMenu";
import FeatureFallback from "./components/FeatureFallback";
import Error404Fallback from "./components/Error404Fallback";

const Dashboard = lazyWithRetry(() => import("./pages/Dashboard"), "Dashboard");
const Settings = lazyWithRetry(() => import("./pages/Settings"), "Settings");
const RelationMenu = lazyWithRetry(
  () => import("./pages/relation/RelationMenu"),
  "RelationMenu"
);
const PreviewProductionOrder = lazyWithRetry(
  () => import("./pages/production-order/PreviewProductionOrderPDF"),
  "PreviewProductionOrder"
);
const PreviewShipmentPDF = lazyWithRetry(
  () => import("./pages/shipment/shipments/details/PreviewShipmentPDF"),
  "PreviewShipmentPDF"
);
const PreviewInvoicePDF = lazyWithRetry(
  () => import("./pages/finances/details/PreviewInvoicePDF"),
  "PreviewInvoicePDF"
);
const PreferenceWrapper = lazyWithRetry(
  () => import("./pages/preferences/PreferenceWrapper"),
  "PreferenceWrapper"
);
const Subscription = lazyWithRetry(
  () => import("./pages/preferences/Subscription"),
  "Subscription"
);
const Profile = lazyWithRetry(
  () => import("./pages/preferences/profile/ProfileWrapper"),
  "ProfileWrapper"
);
const OrderWrapper = lazyWithRetry(
  () => import("./pages/OrderWrapper"),
  "OrderWrapper"
);
const AssetsWrapper = lazyWithRetry(
  () => import("./pages/assets/AssetsWrapper"),
  "AssetsWrapper"
);
const ContractsWrapper = lazyWithRetry(
  () => import("./pages/contracts/ContractsWrapper"),
  "ContractsWrapper"
);
const FinancesWrapper = lazyWithRetry(
  () => import("./pages/finances/FinancesWrapper"),
  "FinancesWrapper"
);
const ShipmentsWrapper = lazyWithRetry(
  () => import("./pages/shipment/ShipmentsWrapper"),
  "ShipmentsWrapper"
);

const routeDictionaries = {
  manage: Settings,
  relations: RelationMenu,
  orders: OrderWrapper,
  preference: PreferenceWrapper,
  assets: AssetsWrapper,
  contracts: ContractsWrapper,
  finance: FinancesWrapper,
  shipment: ShipmentsWrapper,
};

const routes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { menuList } = useMenu(
    (state) => ({
      menuList: state.menuList,
    }),
    shallow
  );

  let mappedRoutes: any = [];
  menuList?.map((menuItem: { key: string; child: any }) => {
    const childMenu =
      menuItem?.child?.length > 0 &&
      menuItem?.child?.map((childItem: any) => {
        return {
          ...childItem,
          exact: true,
          //@ts-ignore
          component: routeDictionaries[menuItem?.key] || FeatureFallback,
          parentKey: menuItem?.key,
        };
      });
    childMenu?.length > 0 && mappedRoutes.push(...childMenu);
    mappedRoutes.push({
      ...menuItem,
      exact: true,
      //@ts-ignore
      component: routeDictionaries[menuItem?.key] || FeatureFallback,
    });
  });

  const staticRoutes = [
    {
      key: "dashboard",
      path: ["/dashboard"],
      exact: true,
      title: "Dashboard",
      component: FeatureFallback,
    },
    {
      key: "subscription",
      path: ["/subscription"],
      exact: true,
      title: "Subscription",
      component: Subscription,
    },
    {
      key: "profile",
      path: ["/profile"],
      exact: true,
      title: "Profile",
      component: Profile,
    },
    {
      key: "preview-production-orders",
      path: ["/preview/production-orders"],
      exact: true,
      title: "Production Order",
      component: PreviewProductionOrder,
    },
    {
      key: "preview-shipment-pdf",
      path: ["/preview/shipment-pdf"],
      exact: true,
      title: "Shipment PDF",
      component: PreviewShipmentPDF,
    },
    {
      key: "preview-invoice-pdf",
      path: ["/preview/invoice-pdf"],
      exact: true,
      title: "Invoice PDF",
      component: PreviewInvoicePDF,
    },
    {
      path: ["*"],
      component: Error404Fallback,
    },
  ];

  const data = [
    {
      component: Dashboard,
      routes: [...mappedRoutes, ...staticRoutes],
    },
  ];

  return data;
};

export default routes;
