import { useEffect, useState } from "react";

import capitalize from "lodash/capitalize";
import ctl from "@netlify/classnames-template-literals";

import InlineDialog from "../../design-system/InlineDialog";
import InlineDialogKeyPress from "../../components/InlineDialogKeyPress";
import RadioButton from "../../design-system/RadioButton";
import Shimmer from "../../design-system/Shimmer";
import SVGIcon from "../../design-system/SVGIcon";
import TooltipContainer from "../../design-system/TooltipContainer";
import {
  focusOnElement,
  focusOnChildElement,
} from "../../helper/keyboardAccessHelper";
import RequiredIcon from "../../design-system/RequiredIcon";

const SelectedTag = ({
  name,
  removeSelected,
  onTab,
  onShiftTab,
  id,
}: {
  name: string;
  removeSelected: () => void;
  onTab: () => void;
  onShiftTab: () => void;
  id?: string;
}) => {
  return (
    <div
      className="p-[0.25rem] flex gap-[0.625rem] w-fit items-center justify-content bg-n-100 rounded-[0.25rem] mt-[0.25rem]"
      id={id}
      data-testid={id}
    >
      <p className="typography-caption text-cn-600" data-testid={`${id}-text`}>
        {name}
      </p>
      <SVGIcon
        iconName="icon-cancel"
        size={16}
        fillColor="var(--n-300)"
        onClick={removeSelected}
        onTab={onTab}
        onShiftTab={onShiftTab}
        id={`${id}-remove-icon`}
      />
    </div>
  );
};
interface IDropdown {
  list: any;
  selected?: any;
  onChange?: (type: any) => void;
  onTab?: () => void;
  onShiftTab?: () => void;
  label?: string;
  isRequired?: boolean;
  widthClass?: string;
  typeView?: string;
  title?: string;
  isLoading?: boolean;
  id?: string;
  keyList?: string;
  tabIndex?: number;
  maxSelection?: number;
  disabled?: boolean;
  customChange?: (type: any) => void;
  wrapperCustomClass?: string;
  widthWrapperClass?: string;
  useCapitalize?: boolean;
  currentIndex?: number | null;
  isHorizontal?: boolean;
  labelCustomClass?: string;
  labelTextCustomClass?: string;
  errorMessage?: string;
  showErrorMessage?: boolean;
  useRemoveButton?: boolean;
  menuCustomClass?: string;
  widthBasedOnChildren?: boolean;
  placeholder?: string;
  unit?: string;
  usePortal?: boolean;
}

interface IItemDropdown {
  id?: string;
  name?: string;
  organizationName?: string;
  code?: string;
}

const FilterDropdown = ({
  list,
  selected,
  onChange,
  onTab,
  onShiftTab,
  label,
  isRequired,
  widthClass = "w-[15.5rem]",
  typeView = "dropdown",
  title,
  isLoading,
  id,
  keyList,
  tabIndex,
  maxSelection = 1,
  disabled,
  customChange,
  wrapperCustomClass = "",
  widthWrapperClass,
  useCapitalize = false,
  currentIndex = null,
  isHorizontal = false,
  labelCustomClass,
  labelTextCustomClass,
  errorMessage,
  showErrorMessage,
  useRemoveButton = false,
  menuCustomClass,
  widthBasedOnChildren = false,
  placeholder,
  unit,
  usePortal,
}: IDropdown) => {
  const [currentSelected, setCurrentSelected] = useState<any>(null);
  const isMax = currentSelected?.length === maxSelection;
  const isOneSelectionOnly = maxSelection === 1;
  const currentId = currentIndex !== null ? `${id}+${currentIndex}` : id;
  const isAlreadySelect = maxSelection === 1 && currentSelected;
  const _disabled = (!isOneSelectionOnly && isMax) || disabled;

  const getSelected = (selected: any) => {
    return keyList ? selected?.[keyList] : selected;
  };

  const changeFunction = (sel: any) => {
    if (customChange) {
      customChange(sel);
      return;
    }

    onChange && onChange(sel);
  };

  const _onChange = (sel: any) => {
    if (maxSelection === 1) {
      const _selected = getSelected(sel);
      setCurrentSelected(_selected);
      changeFunction(sel);
      return;
    }
    const selectedIds = currentSelected?.map((sel: any) => sel.id);
    if (selectedIds?.includes(sel?.id)) {
      removeSelected(sel?.id);
      return;
    }
    changeFunction([...(currentSelected || []), sel]);
    setCurrentSelected([...(currentSelected || []), sel]);
  };

  const removeSelected = (removedId: string, removedIndex?: number) => {
    const newCurrentSelected =
      currentSelected?.filter((tag: any) => tag?.id !== removedId) || [];

    changeFunction(newCurrentSelected);
    setCurrentSelected(newCurrentSelected);

    if (newCurrentSelected?.length === 0) {
      id && focusOnElement(id);
    } else if (newCurrentSelected?.length > 0 && removedIndex) {
      const newIndex =
        removedIndex +
        (removedIndex === 0
          ? 1
          : removedIndex >= currentSelected?.length - 1
          ? -1
          : 0);
      const childId = `selected-${newIndex}-remove-icon`;
      focusOnChildElement(`${id}-wrapper`, childId);
    }
  };

  const onTab_ = () => {
    if ((currentSelected || [])?.length > 0 && maxSelection !== 1) {
      const childId = "selected-0-remove-icon";
      focusOnChildElement(`${id}-wrapper`, childId);
    } else {
      onTab && onTab();
    }
  };

  const onTabSelectedTag = (index: number) => {
    if (index >= (currentSelected || [])?.length - 1) {
      onTab && onTab();
    } else {
      const childId = `selected-${index + 1}-remove-icon`;
      focusOnChildElement(`${id}-wrapper`, childId);
    }
  };

  const onShiftTabSelectedTag = (index: number) => {
    if (index === 0) {
      id && focusOnElement(id);
    } else {
      const childId = `selected-${index - 1}-remove-icon`;
      focusOnChildElement(`${id}-wrapper`, childId);
    }
  };

  // const tabPressed = useKeyPress("Tab", currentId);

  // useEffect(() => {
  //   if (tabIndex === -1) return;
  //   tabPressed &&
  //     currentId &&
  //     document.activeElement === document.getElementById(currentId) &&
  //     document.getElementById(currentId)?.click();
  // }, [tabPressed]);

  useEffect(() => {
    const _selected = isOneSelectionOnly ? getSelected(selected) : selected;
    setCurrentSelected(_selected);
  }, [selected]);

  const wrapperLabelCN = ctl(`
    flex items-center gap-[0.25rem] 
    ${isHorizontal ? "h-[2.625rem]" : "h-fit mb-[0.25rem]"}
    ${labelCustomClass}
  `);

  const labelCN = ctl(`
    text-cn-600
    ${!labelTextCustomClass?.includes("typography") && "typography-caption"}
    ${isHorizontal && !labelCustomClass && "w-[7.25rem]"}
    ${labelTextCustomClass}
  `);

  const triggerCN = ctl(`
    border border-solid rounded-[0.25rem]
    bg-n-000 ${widthClass} 
    ${unit ? "" : "px-[0.625rem] py-[0.5rem]"}
    ${
      disabled
        ? "border-transparent"
        : errorMessage
        ? "border-cd-600"
        : "border-cn-300 hover:border-cp-500"
    }
  `);

  const triggerTextCN = ctl(`
    ${unit ? "px-[0.625rem] py-[0.5rem]" : ""}
  `);

  return (
    <div className={widthWrapperClass || ""} id={`${id}-wrapper`}>
      <div
        className={`${widthWrapperClass ? "w-full" : ""} ${
          isHorizontal ? "flex gap-[0.75rem] items-center" : ""
        } ${wrapperCustomClass}`}
      >
        {label && (
          <div className={wrapperLabelCN}>
            <p className={labelCN} data-testid={`${currentId}-label`}>
              {label}
              {isRequired && <RequiredIcon id={currentId} />}
            </p>
          </div>
        )}

        <div
          className={widthWrapperClass ? "w-full" : ""}
          data-testid={currentId}
        >
          {typeView === "dropdown" ? (
            <>
              <InlineDialog
                onTab={onTab_}
                onShiftTab={onShiftTab}
                customClass={widthWrapperClass ? "w-full" : ""}
                usePortal={usePortal}
                useBgColor
                id={currentId}
              >
                <InlineDialog.Trigger
                  customClass={triggerCN}
                  customTextClass={triggerTextCN}
                  useArrow
                  disabled={_disabled}
                  tabIndex={tabIndex}
                  unit={unit}
                  {...(!isOneSelectionOnly && isMax
                    ? {
                        disabledText: `Maximum ${maxSelection} option selected`,
                      }
                    : {})}
                >
                  <div
                    className={`flex justify-between items-center ${
                      isAlreadySelect ? "w-[calc(100%-24px)]" : ""
                    }`}
                  >
                    <TooltipContainer
                      show={isAlreadySelect}
                      text={
                        useCapitalize
                          ? capitalize(currentSelected || "")
                          : currentSelected || ""
                      }
                      customClass={
                        isAlreadySelect && useRemoveButton
                          ? "w-[calc(100%-24px)]"
                          : "w-full"
                      }
                    >
                      <p
                        id={currentId}
                        tabIndex={tabIndex}
                        className={`${
                          isAlreadySelect || disabled
                            ? "text-cn-600"
                            : "text-cn-400"
                        }  typography-body truncate`}
                      >
                        {isAlreadySelect
                          ? useCapitalize
                            ? capitalize(currentSelected || "")
                            : currentSelected || ""
                          : placeholder ?? title}
                      </p>
                    </TooltipContainer>

                    {isAlreadySelect && useRemoveButton && (
                      <SVGIcon
                        iconName="icon-cancel"
                        size={16}
                        fillColor="var(--n-300)"
                        onClick={(e) => {
                          e?.stopPropagation();
                          _onChange(null);
                        }}
                      />
                    )}
                  </div>
                </InlineDialog.Trigger>
                <InlineDialog.MenuItems
                  widthBasedOnChildren={widthBasedOnChildren}
                  vPosition="bottom"
                  customClass={widthBasedOnChildren ? "min-w-full" : "w-full"}
                  {...(widthBasedOnChildren ? { position: "left" } : {})}
                >
                  {isLoading ? (
                    <Shimmer customClass="m-[0.5rem]" heightClass="h-[1rem]" />
                  ) : (
                    <InlineDialogKeyPress
                      list={list}
                      onChange={_onChange}
                      useCapitalize={useCapitalize}
                      keyList={keyList}
                      menuCustomClass={menuCustomClass}
                      widthBasedOnChildren={widthBasedOnChildren}
                      onTab={onTab_}
                      onShiftTab={onShiftTab}
                    />
                  )}
                </InlineDialog.MenuItems>
              </InlineDialog>
              {errorMessage && showErrorMessage && (
                <p
                  className="typography-caption text-r-400 mt-[0.25rem]"
                  data-testid={`${currentId}-error-message`}
                >
                  {errorMessage}
                </p>
              )}
            </>
          ) : (
            <div className="flex gap-[0.75rem] wrap">
              {list?.map((type: any) => (
                <RadioButton
                  isActive={selected === (keyList ? type[keyList] : type)}
                  setIsActive={() => onChange && onChange(type)}
                >
                  <p className="typography-caption text-cn-600 ml-[0.25rem]">
                    {keyList ? type[keyList] : type}
                  </p>
                </RadioButton>
              ))}
            </div>
          )}
        </div>
      </div>
      {maxSelection !== 1 && (
        <div
          className={`flex gap-[0.25rem] flex-wrap ${
            isHorizontal ? "ml-[8.75rem]" : ""
          } ${widthClass}`}
        >
          {(currentSelected || [])?.map((filter: any, index: number) => (
            <SelectedTag
              name={keyList ? filter?.[keyList] : selected}
              removeSelected={() => removeSelected(filter?.id, index)}
              onTab={() => onTabSelectedTag(index)}
              onShiftTab={() => onShiftTabSelectedTag(index)}
              id={`selected-${index}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
export type { IItemDropdown };
