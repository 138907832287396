import dayjs from "dayjs";
import { IFilterSort } from "../design-system/table/TableInterface";
import routes from "../Routes";

const backToList = (history: any, pathname: string, state: any) => {
  history.push({
    pathname: pathname,
    state: { ...(state || {}), view: "view", viewData: null },
  });
};

const resetTimeToMidnight = (isoTime: string, type: "start" | "end") => {
  const localDate = dayjs(new Date(isoTime))?.format("DD-MM-YYYY");
  // Split the input string to extract month, day, and year
  const [day, month, year] = localDate.split("-").map(Number);

  // Create a Date object with the parsed date (month is 0-indexed, so subtract 1)
  const date =
    type === "start"
      ? dayjs(`${year}-${month}-${day}`).startOf("day").toISOString()
      : dayjs(`${year}-${month}-${day}`).endOf("day").toISOString();

  // Convert the Date object to ISO string
  return date;
};

const generateDate = (data: string | null | undefined) => {
  if (!data) return "--";
  const formatDate = (date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };
  return formatDate(new Date(data));
};

const generateSort = (
  sortName: string,
  sortData: IFilterSort,
  setSortData: (sort: IFilterSort) => void,
  columnName?: string
) => {
  return {
    default: "DESC",
    isActive: sortData?.sortColumn === sortName,
    activeColumn: columnName ?? sortData?.sortColumn,
    onSort: (currentDirection: string) => {
      setSortData({
        sortColumn: sortName,
        sortDirection: currentDirection,
      });
    },
  };
};

const getPageMaxWidth = (
  isSidebarMax: boolean,
  maxScreenWidthInPx: number = 1920
) => {
  // const sidebarWidth = isSidebarMax ? 17.75 : 5.375; // rem
  const sidebarWidth = isSidebarMax ? 284 : 86; // px
  return pxToRem(maxScreenWidthInPx - sidebarWidth);
};

export const flattenPages = (pages: any) => {
  return (
    pages
      ?.flatMap((page: any) => {
        if (!page?.isSuccess) return;
        return page?.data;
      })
      .filter((d: any) => d) || []
  );
};

const pxToRem = (sizeInPx: number) => {
  // return sizeInPx / (window.innerWidth >= 1200 ? 16 : 12);
  return sizeInPx / 16;
};

const getWidthFromClass = (widthClass: string) => {
  return widthClass?.includes("w-full")
    ? "100%"
    : widthClass?.replace("w-[", "")?.replace("]", "");
};

const formatNumber = (
  number: number | string | undefined | null,
  prefix: string = "",
  suffix: string = "",
  precision: number = 0,
  fallback: string = "--"
) => {
  if (!number && number !== 0) return fallback;
  const number_ = typeof number === "number" ? number : parseFloat(number);
  if (isNaN(number_)) return fallback;
  return `${prefix}${number_?.toLocaleString("DE-de", {
    minimumFractionDigits: precision,
  })}${suffix}`;
};

const hasPermission = (pathname: string, permissionAction: string) => {
  const data = routes();
  const permission = data[0]?.routes
    ?.find((route: any) => route?.path?.[0] === pathname)
    ?.permission?.find(
      (permissionItem: any) =>
        permissionItem?.action?.toLowerCase() ===
        permissionAction?.toLowerCase()
    );

  return permission?.allow ?? false;
};

const compileAddress = (location: any) => {
  return (
    <>
      {location?.locationName && (
        <>
          {location?.locationName} <br />
        </>
      )}
      {location?.address && (
        <>
          {location?.address} <br />
        </>
      )}
      {location?.place && (
        <>
          {location?.place} <br />
        </>
      )}
      {location?.postCode && (
        <>
          {location?.postCode} <br />
        </>
      )}
      {location?.country?.country}
    </>
  );
};

export {
  backToList,
  resetTimeToMidnight,
  generateDate,
  generateSort,
  getPageMaxWidth,
  pxToRem,
  getWidthFromClass,
  formatNumber,
  hasPermission,
  compileAddress,
};
