import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import isEmpty from "lodash/isEmpty";

import useToast from "../../hooks/useToast";
import InputField from "../../design-system/InputField";
import Button from "../../design-system/Button";
import SignWrapper from "./SignWrapper";
import { cloneDeep, isEqual } from "lodash";
import apiClient from "../../hooks/apiClient";
import SVGIcon from "../../design-system/SVGIcon";
import LoadingDots from "../../design-system/LoadingDots";

const ResetPassword = () => {
  const history = useHistory();
  const usp = new URLSearchParams(window.location.search);
  const userToken = usp.get("token");
  const userId = usp.get("user-id");

  const [isUserExist, setIsUserExist] = useState(false);
  const [showFirstPassword, setShowFirstPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setToastData = useToast((state) => state.setToastData);

  const [loginData, setLoginData] = useState({
    password: "",
    verifyPassword: "",
  });
  const [error, setError] = useState({
    minLengthRegex: true,
    uppercaseRegex: true,
    digitRegex: true,
    specialCharRegex: true,
    isVerifySame: false,
  });

  const resetPassword = async () => {
    const { isSuccess, error } = await apiClient(
      `api/v1/user/update_password`,
      "POST",
      {
        body: {
          token: userToken,
          password: loginData?.password,
        },
      },
      "application/json",
      false
    );

    if (isSuccess) {
      history.push({
        pathname: "/login",
        state: {
          isFromResetPassword: true,
        },
      });
      return;
    }

    setToastData({
      show: true,
      warning: true,
      message: error || "Please enter the correct email and password",
    });
  };

  const changeData = (value: string, key: "password" | "verifyPassword") => {
    const value_ = value?.trim();
    const cloneData = cloneDeep(loginData);
    cloneData[key] = value_;
    setLoginData(cloneData);
    if (key === "password") {
      const minLengthRegex = /^.{8,}$/;
      const uppercaseRegex = /^(?=.*[A-Z])/;
      const digitRegex = /^(?=.*\d)/;
      const specialCharRegex = /^(?=.*[@#$%^&+=!])/;
      setError({
        ...error,
        minLengthRegex: !minLengthRegex.test(value_),
        uppercaseRegex: !uppercaseRegex.test(value_),
        digitRegex: !digitRegex.test(value_),
        specialCharRegex: !specialCharRegex.test(value_),
        ...(cloneData?.verifyPassword?.length > 0 && {
          isVerifySame: !isEqual(
            cloneData?.password,
            cloneData?.verifyPassword
          ),
        }),
      });
      return;
    }

    !isEmpty(value_) &&
      setError({
        ...error,
        isVerifySame: !isEqual(cloneData?.password, cloneData?.verifyPassword),
      });
  };

  const checkUserData = async () => {
    setIsLoading(true);
    if (!userToken || !userId) return;
    const { isSuccess } = await apiClient(
      `api/v1/password_reset/${userId}/${userToken}`,
      "PUT",
      {}
    );

    setIsUserExist(isSuccess);
    setIsLoading(false);
  };

  useEffect(() => {
    checkUserData();
  }, []);

  return (
    <SignWrapper paddingXClass="px-[2.5rem]">
      {isLoading ? (
        <LoadingDots />
      ) : isUserExist ? (
        <>
          <p className="typography-h2-bold text-n-700">Reset password</p>

          <div className="flex flex-col gap-[0.5rem]">
            <p className="typography-body-bold text-n-700">
              Enter New Password
            </p>
            <InputField
              value={loginData?.password}
              onChange={(val: string) => changeData(val, "password")}
              customClass="w-full"
              type={showFirstPassword ? "text" : "password"}
              insideElement={
                <SVGIcon
                  iconName={showFirstPassword ? "icon-eye" : "icon-eye-off"}
                  size={16}
                  fillColor="var(--n-700)"
                  customClass="mr-[0.5rem] absolute right-[0.25rem] top-[0.825rem] cursor-not-allowed"
                  onClick={() => setShowFirstPassword(!showFirstPassword)}
                />
              }
              id="password"
            />
            <div className="flex flex-col gap-[0.25rem]">
              <p
                className={`typography-body ${
                  error?.minLengthRegex ? "text-n-300" : "text-b-400"
                }`}
              >
                The password must be at least 8 characters long
              </p>
              <p
                className={`typography-body ${
                  error?.uppercaseRegex ? "text-n-300" : "text-b-400"
                }`}
              >
                Include at least one uppercase letter (A-Z)
              </p>
              <p
                className={`typography-body ${
                  error?.digitRegex ? "text-n-300" : "text-b-400"
                }`}
              >
                Include at least one numerical digit (0-9)
              </p>
              <p
                className={`typography-body ${
                  error?.specialCharRegex ? "text-n-300" : "text-b-400"
                }`}
              >
                Include at least one special character
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[0.5rem]">
            <p className="typography-body-bold text-n-700">Verify Password</p>
            <InputField
              value={loginData?.verifyPassword}
              onChange={(val: string) => changeData(val, "verifyPassword")}
              customClass="w-full"
              type={showSecondPassword ? "text" : "password"}
              errorMessage={
                error?.isVerifySame ? "Please check password" : null
              }
              insideElement={
                <SVGIcon
                  iconName={showSecondPassword ? "icon-eye" : "icon-eye-off"}
                  size={16}
                  fillColor="var(--n-700)"
                  customClass="mr-[0.5rem] absolute right-[0.25rem] top-[0.825rem] cursor-not-allowed"
                  onClick={() => setShowSecondPassword(!showSecondPassword)}
                />
              }
              id="verify-password"
            />
          </div>
          <Button
            id="login"
            customClass="w-full h-[3.875rem]"
            onClick={resetPassword}
            disabled={
              isEmpty(loginData?.password) ||
              isEmpty(loginData?.verifyPassword) ||
              !Object.values(error).every((err) => err === false)
            }
          >
            Confirm
          </Button>
        </>
      ) : (
        <>
          <p className="typography-h2-bold text-n-700">Invalid Link</p>
          <p className="typography-subtitle-bold text-n-700">
            The link might have been expired
          </p>
          <Button
            id="back-to-login"
            customClass="w-full h-[3.875rem]"
            onClick={() => history.replace("/login")}
          >
            Back to Login
          </Button>
        </>
      )}
    </SignWrapper>
  );
};

export default ResetPassword;
