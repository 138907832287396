import { ChangeEvent, KeyboardEvent } from "react";
import ctl from "@netlify/classnames-template-literals";

interface ICheckbox {
  id: string;
  checked: boolean;
  onChange: (
    e: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
  ) => void;
  onTab?: () => void;
  onShiftTab?: () => void;
  value: string;
  disabled?: boolean;
  size?: "small" | "large";
  isRound?: boolean;
}

const Checkbox = ({
  checked = false,
  onChange,
  onTab,
  onShiftTab,
  value,
  id,
  disabled = false,
  size = "small",
  isRound = true,
}: ICheckbox) => {
  const WrapperCN = ctl(`
    relative flex items-center p-[0.25rem]
    focus-within:[&:has(:focus-visible)]:border focus-within:[&:has(:focus-visible)]:border-solid
    focus-within:[&:has(:focus-visible)]:border-cp-600 focus-within:[&:has(:focus-visible)]:p-[0.1875rem]
    ${isRound ? "rounded-full" : "rounded-[0.125rem]"}
    ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
  `);

  const CheckboxWrapperCN = ctl(`
    before:content[''] peer relative 
    ${size === "small" ? "h-[1.25rem] w-[1.25rem]" : "h-[1.5rem] w-[1.5rem]"}
    appearance-none border border-solid 
    transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 
    before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity 
    ${
      isRound
        ? "rounded-full before:rounded-full"
        : "rounded-[0.125rem] before:rounded-[0.125rem]"
    }
    ${
      disabled
        ? "cursor-not-allowed bg-cn-100 before:bg-cn-400 checked:border-cn-400 checked:bg-cn-400 checked:before:bg-cn-400 border-cn-300"
        : `cursor-pointer hover:scale-105 hover:before:opacity-0 bg-cn-white border-cn-300
            before:bg-cp-600 checked:border-cp-600 checked:bg-cp-600 checked:before:bg-cp-600`
    }
  `);

  const CheckboxIconCN = ctl(`
    absolute text-white transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100  -translate-y-2/4 -translate-x-2/4 p-[0.25rem]
    ${size === "small" && "top-[55%] left-[50%]"}
    ${size === "large" && "top-[60%] left-[55%]"}
  `);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e?.key === "Tab") {
      e.preventDefault();
      if (e?.shiftKey) {
        onShiftTab && onShiftTab();
      } else {
        onTab && onTab();
      }
    }
    if (e?.key === " " || e?.key === "Enter") {
      e.preventDefault();
      !disabled && onChange && onChange(e);
    }
  };

  return (
    <div className="inline-flex items-center">
      <label className={WrapperCN} htmlFor={id} data-testid={`${id}-checkbox`}>
        <input
          type="checkbox"
          className={CheckboxWrapperCN}
          checked={checked}
          name={id}
          value={value}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            !disabled && onChange && onChange(e);
          }}
          onKeyDown={onKeyDown}
          disabled={disabled}
          tabIndex={-1}
          id={id}
        />
        <span className={CheckboxIconCN} data-testid={`${id}-check-icon`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={
              size === "small" ? "h-3.5 w-3.5" : "h-[1.25rem] w-[1.25rem]"
            }
            viewBox={size === "small" ? "0 0 20 20" : "0 0 24 24"}
            fill="currentColor"
            stroke="currentColor"
            stroke-width="1"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </span>
      </label>
    </div>
  );
};

export default Checkbox;
