import { renderRoutes } from "react-router-config";
import { Switch } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";

import routes from "../Routes";
import React, { useEffect } from "react";
import useUser from "../hooks/useUser";
import isEmpty from "lodash/isEmpty";
import apiClient from "../hooks/apiClient";
import { useQuery } from "@tanstack/react-query";

const AuthenticatedPage = () => {
  const data = routes();
  const userIdinLocalStorage = localStorage.getItem("userId");

  const userData = useUser((state) => state.userData);
  const setUserData = useUser((state) => state.setUserData);
  const organizationData = useUser((state) => state.organizationData);
  const setOrganizationData = useUser((state) => state.setOrganizationData);

  const getUserData = () => {
    return apiClient(`api/v1/user/${userIdinLocalStorage}`, "GET", {});
  };

  const { data: dataQuery, isSuccess } = useQuery({
    queryKey: ["user", userIdinLocalStorage],
    queryFn: getUserData,
    enabled: isEmpty(userData) && !!userIdinLocalStorage,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const getOrganizationData = async () => {
    const { data } = await apiClient(`api/v1/preference`, "GET", {
      params: {
        groupName: "Account",
      },
    });

    if (data) setOrganizationData(data);
  };

  useEffect(() => {
    isEmpty(organizationData) && getOrganizationData();
  }, [userIdinLocalStorage]);

  useEffect(() => {
    if (!isSuccess) return;
    setUserData(dataQuery?.data);
  }, [dataQuery]);

  return (
    <React.Suspense fallback={<></>}>
      <Router>
        <Switch>{renderRoutes(data)}</Switch>
      </Router>
    </React.Suspense>
  );
};

export default AuthenticatedPage;
