import { useInfiniteQuery } from "@tanstack/react-query";
import apiClient from "../hooks/apiClient";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import Select from "react-dropdown-select";
import SVGIcon from "./SVGIcon";

import "./SearchDropdown.scss";
import Button from "./Button";
import ctl from "@netlify/classnames-template-literals";
import { useEffect, useMemo, useState, useCallback, createRef } from "react";
import Shimmer from "./Shimmer";
import useDebounce from "../hooks/useDebounce";
import {
  dropdownKeyList,
  focusOnElement,
  onKeyPressDropdownTrigger,
} from "../helper/keyboardAccessHelper";
import RequiredIcon from "./RequiredIcon";
import {
  flattenPages,
  getWidthFromClass,
  pxToRem,
} from "../helper/generalUtils";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import TooltipContainer from "./TooltipContainer";

interface ISelectedDropdown {
  id: string;
  name: string;
  organizationName?: string;
  assetName?: string;
  code?: string;
  title?: string;
  refCode?: string;
  value?: string | number;
}

interface ISearchDropdown {
  disabled?: boolean;
  selected: ISelectedDropdown | ISelectedDropdown[] | undefined | null;
  fetchData?: {
    searchUrl?: string;
    params?: any;
  };
  customList?: ISelectedDropdown[];
  id: string;
  currentIndex?: number | null;
  onTab?: () => void;
  onShiftTab?: () => void;
  onChange: (val: ISelectedDropdown | ISelectedDropdown[] | undefined) => void;
  isRequired?: boolean;
  label?: string;
  widthClass?: string;
  widthStyle?: string;
  onSuccess?: () => void;
  tabIndex?: number;
  addAction?: {
    text: string;
    iconName: string;
    onClick: (searchValue: string) => void;
  };
  additionalQueryKey?: string[];
  placeholder?: string;
  initialData?: any;
  setDropdownDataList?: (val: any) => void;
  keyData?: string;
  isHorizontal?: boolean;
  labelCustomClass?: string;
  labelTextCustomClass?: string;
  wrapperCustomClass?: string;
  customClass?: string;
  customStyle?: Object;
  keyItem?: string;
  searchKeyItem?: string | null;
  keyItems?: string[];
  tryKeyItems?: string[];
  errorMessage?: string;
  showErrorMessage?: boolean;
  insideElement?: JSX.Element | React.ReactNode;
  useClearButton?: boolean;
  useSearchValueToUrl?: boolean;
  useSearchValueToParams?: boolean;
  maxOption?: number | null;
  ableToAddNewOption?: boolean;
  customDropdownOption?: (option: any, index: number) => JSX.Element;
  customDropdownHeader?: JSX.Element;
  customContent?: ({ props }: any) => JSX.Element;
  usePortal?: boolean;
  useSelectedWithTag?: boolean;
  isFullWidth?: boolean;
  multi?: boolean;
  inModal?: boolean;
  unit?: string;
  additionalParams?: Object;
}

const SearchDropdown = ({
  disabled,
  selected,
  fetchData,
  customList,
  id,
  currentIndex = null,
  onChange,
  isRequired,
  label,
  widthClass = "",
  widthStyle = "",
  onSuccess,
  onTab,
  onShiftTab,
  tabIndex = -1,
  addAction,
  isHorizontal = false,
  labelCustomClass,
  labelTextCustomClass,
  wrapperCustomClass,
  customClass = "",
  customStyle = {},
  keyItem = "name",
  searchKeyItem = null,
  keyItems = [],
  tryKeyItems = [],
  additionalQueryKey,
  placeholder,
  initialData = null,
  setDropdownDataList,
  keyData,
  errorMessage,
  showErrorMessage,
  insideElement,
  useClearButton,
  useSearchValueToUrl = false,
  useSearchValueToParams = false,
  maxOption = null,
  ableToAddNewOption = false,
  customDropdownOption,
  customDropdownHeader,
  customContent,
  usePortal = false,
  useSelectedWithTag = false,
  isFullWidth = false,
  multi = false,
  inModal = false,
  unit,
  additionalParams,
}: ISearchDropdown) => {
  const [searchValue, setSearchValue] = useState("");
  const [optionsMaxHeight, setOptionsMaxHeight] = useState<number>(18.75);
  const [dropdownHeaderRect, setDropdownHeaderRect] = useState<DOMRect>();
  const [maxWidth, setMaxWidth] = useState<string>("");
  const [unitRect, setUnitRect] = useState<DOMRect>();

  const currentId = currentIndex !== null ? `${id}+${currentIndex}` : id;

  const intersectTarget = createRef<HTMLDivElement>();

  const handleDropdownHeaderRect = useCallback((node: HTMLDivElement) => {
    setDropdownHeaderRect(node?.getBoundingClientRect());
  }, []);

  const handleUnitRect = useCallback((node: HTMLDivElement) => {
    setUnitRect(node?.getBoundingClientRect());
  }, []);

  const mainbody = document.querySelector<HTMLElement>(".main-body");
  const styledScrollbar =
    document.querySelectorAll<HTMLElement>("#styled-scrollbar");

  const disablePageScroll = () => {
    mainbody?.style.setProperty("overflow", "hidden", "important");

    for (let i = 0; i < styledScrollbar?.length; i++) {
      styledScrollbar[i].style.setProperty("overflow", "hidden", "important");
    }
  };

  const enablePageScroll = () => {
    if (mainbody) mainbody.style.overflow = "";

    for (let i = 0; i < styledScrollbar?.length; i++) {
      styledScrollbar[i].style.overflow = "";
    }
  };

  const getData = async (pageParam: number) => {
    if (disabled || !fetchData?.searchUrl) return;
    const _searchUrl = useSearchValueToUrl
      ? `${fetchData?.searchUrl}/${searchValue}`
      : fetchData?.searchUrl;
    return apiClient(_searchUrl, "GET", {
      params: {
        ...fetchData?.params,
        ...(useSearchValueToParams && !isEmpty(searchValue)
          ? { [searchKeyItem ?? keyItem]: searchValue }
          : {}),
        ...(pageParam &&
          fetchData?.params?.limit && {
            offset: (pageParam - 1) * fetchData?.params?.limit,
          }),
      },
      isShowErrorToast: false,
    });
  };

  const generateQueryKey = () => {
    const _key = [id];
    additionalQueryKey && _key.push(...additionalQueryKey);
    ((useSearchValueToUrl && !isEmpty(searchValue)) ||
      (useSearchValueToParams && !isEmpty(useSearchValueToParams))) &&
      _key.push(searchValue);
    return _key;
  };

  const memoQueryKey = useMemo(
    () => generateQueryKey(),
    [additionalQueryKey, searchValue]
  );
  const debouncedMemoQueryKey = useDebounce(memoQueryKey, 500);
  const isEnableQuery = useMemo(
    () =>
      !disabled &&
      !initialData &&
      !!fetchData &&
      (useSearchValueToUrl ? !isEmpty(searchValue) : true),
    [disabled, initialData, fetchData, useSearchValueToUrl, searchValue]
  );

  const {
    data: dataQuery,
    refetch,
    isSuccess,
    isLoading,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: debouncedMemoQueryKey,
    queryFn: ({ pageParam = 1 }: any) => getData(pageParam),
    initialPageParam: null,
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage?.isSuccess &&
        allPages?.data?.length < (fetchData?.params?.limit || 10)
        ? allPages?.length + 1
        : undefined;
    },
    staleTime: 60 * 1000,
    gcTime: 60 * 1000,
    enabled: isEnableQuery,
    refetchOnWindowFocus: false,
  });

  useIntersectionObserver({
    target: intersectTarget,
    onIntersect: (entry) => entry.isIntersecting && fetchNextPage(),
    disabled: isLoading || isFetching || isFetchingNextPage || !hasNextPage,
    threshold: 0,
  });

  // useEffect(() => {
  //   refetch();
  // }, [additionalParams, refetch]);

  useEffect(() => {
    if (!useSearchValueToParams) return;
    refetch();
  }, [debouncedMemoQueryKey]);

  const _dataQuery = initialData
    ? initialData
    : dataQuery
    ? flattenPages(dataQuery?.pages)
    : [];

  const list =
    customList ??
    ((keyData
      ? _dataQuery?.[keyData] ?? _dataQuery?.[0]?.[keyData]
      : _dataQuery) ||
      []);

  const onSearch = ({ props, state, methods }: any) => {
    const _seachValue = state.search?.toLowerCase();
    setSearchValue(_seachValue);

    if (useSearchValueToUrl || useSearchValueToParams) return list;

    const doesItemExist = (item: ISelectedDropdown, key: string) => {
      //@ts-ignore
      return item?.[key]?.toLowerCase()?.includes(_seachValue);
    };

    let searchResult = cloneDeep(list)?.filter((item: ISelectedDropdown) =>
      keyItems?.length > 0
        ? keyItems.some((keyItem) => doesItemExist(item, keyItem))
        : tryKeyItems?.length > 0
        ? doesItemExist(
            item,
            //@ts-ignore
            tryKeyItems.find((keyItem) => item?.[keyItem])
          )
        : doesItemExist(item, keyItem)
    );
    if (maxOption) {
      searchResult = searchResult?.slice(0, maxOption);
    }

    return searchResult;
  };

  const _onChange = (values: ISelectedDropdown[]) => {
    const value_ = multi ? values : values?.[0];
    if (isEqual(selected, value_)) return;
    onChange(value_);
    focusOnElement(currentId);
    onSuccess && onSuccess();
  };

  const CustomDropdownHandleRenderer = ({ props, methods, state }: any) => {
    return (
      <div className="flex items-center">
        <SVGIcon
          iconName={state?.dropdown ? "icon-arrow-up" : "icon-arrow-down"}
          size={16}
          fillColor="var(--cn-400)"
          onClick={props.onClick}
        />
      </div>
    );
  };

  const setPosition = () => {
    const el = document.getElementsByName(currentId)?.[0];
    const rect = el?.getBoundingClientRect();
    var d = document.getElementsByClassName(
      "react-dropdown-select-dropdown"
    )?.[0];

    if (!d) return;

    if (inModal) {
      // @ts-ignore
      d.style.zIndex = 52;
    }

    if (rect?.bottom + d?.clientHeight + 6 <= window.innerHeight) {
      // @ts-ignore
      d.style.top = rect?.top + 30 + "px";
    } else if (rect?.top - d?.clientHeight > 0) {
      // @ts-ignore
      d.style.top = rect?.top - d?.clientHeight - 18 + "px";
    } else {
      // @ts-ignore
      d.style.top = window.innerHeight - rect?.top;
    }
    // @ts-ignore
    d.style.left = rect?.left - 12 + "px";
  };

  const CustomLoadingDropdownRenderer = () => {
    return (
      <Shimmer
        customClass="m-[0.5rem]"
        heightClass="h-[1rem]"
        widthClass="w-[calc(100%-1rem)]"
      />
    );
  };

  const CustomDropdownRenderer = ({ props, state, methods }: any) => {
    const options =
      state?.search === "" || useSearchValueToUrl
        ? props?.options
        : state?.searchResults;

    const isShowAnotherOption = ableToAddNewOption && !!state?.search;

    const getPlaceholder = () => {
      if (isShowAnotherOption) return state?.search;
      if (useSearchValueToUrl) return "Please type your keyword first";
      return "No Option";
    };

    const PlaceholderCN = ctl(`
      typography-body px-[0.625rem] py-[0.5rem] ${
        useSearchValueToUrl && !isShowAnotherOption
          ? "text-cn-400"
          : "text-cn-600"
      }
      ${isShowAnotherOption && "cursor-pointer"}
    `);

    useEffect(() => {
      if (!usePortal && !inModal) return;
      setPosition();
    }, [props?.options, state?.searchResults]);

    useEffect(() => {
      if (state?.cursor === options?.length) {
        if (addAction?.text) {
          document
            .getElementById("add-button")
            ?.scrollIntoView({ block: "nearest" });
          focusOnElement("add-button");
        } else {
          focusOnElement(currentId);
        }
      } else {
        document
          .getElementById(`${currentId}+option-${state?.cursor}`)
          ?.scrollIntoView({ block: "nearest" });
        focusOnElement(`${currentId}+option-${state?.cursor}`);
      }
    }, [state?.cursor]);

    return (
      <div
        className="flex flex-col w-full min-w-fit"
        data-testid={`${currentId}+options-container`}
      >
        {customDropdownHeader && (
          <div
            className="px-[0.625rem] py-[0.5rem] bg-n-100"
            ref={handleDropdownHeaderRect}
          >
            {customDropdownHeader}
          </div>
        )}
        <div
          className="flex flex-col w-full min-h-[2.3125rem] overflow-auto"
          style={{ maxHeight: `${optionsMaxHeight}rem` }}
        >
          {options?.length === 0 && (
            <div
              className={PlaceholderCN}
              onClick={() =>
                ableToAddNewOption &&
                methods.addItem({ [keyItem]: state?.search })
              }
            >
              {getPlaceholder()}
            </div>
          )}
          {options?.map((opt: any, index: number) => (
            <span
              role="option"
              aria-selected={state?.cursor === index ? "true" : "false"}
              aria-label="search-dropdown"
              tabIndex={-1}
              className={`typography-body text-cn-600 px-[0.625rem] py-[0.5rem] hover:bg-b-100 w-full cursor-pointer ${
                state?.cursor === index
                  ? "bg-b-100"
                  : index % 2 === 0
                  ? "bg-n-002"
                  : "bg-n-000"
              }`}
              onClick={() => methods.addItem(opt)}
              id={`${currentId}+option-${index}`}
              data-testid={`${currentId}+option-${index}`}
              key={index}
            >
              {customDropdownOption
                ? customDropdownOption(opt, index)
                : opt?.[keyItem] || "--"}
            </span>
          ))}
          {isFetchingNextPage && (
            <div>
              <CustomLoadingDropdownRenderer />
            </div>
          )}
          {!isLoading && !isFetching && !isFetchingNextPage && hasNextPage && (
            <div ref={intersectTarget} />
          )}
        </div>
        {addAction?.text && (
          <div className="w-full flex items-center justify-start py-[0.25rem]">
            <Button.Secondary
              id="add"
              wrapperCustomClass={
                state?.cursor === options?.length
                  ? "border border-solid border-cp-400 p-[0.1875rem]"
                  : ""
              }
              customClass="py-[0.375rem] px-[1rem] gap-[0.25rem]"
              typographyClass="typography-body-bold"
              onClick={() => {
                methods?.dropDown && methods?.dropDown("close");
                addAction?.onClick(state?.search);
              }}
              noPadding={true}
            >
              {addAction?.iconName && (
                <SVGIcon
                  iconName={addAction?.iconName}
                  size={16}
                  fillColor="var(--b-400)"
                />
              )}
              {addAction?.text}
            </Button.Secondary>
          </div>
        )}
      </div>
    );
  };

  const CustomSelectComponent = ({ props, state, methods }: any) => {
    const { values } = props || {};

    if (values?.length === 0)
      return (
        <p className="text-cn-400 typography-body">
          {placeholder || "Please Select"}
        </p>
      );

    const selectedComponent = (index: any) =>
      values?.[index]?.[
        tryKeyItems?.length > 0
          ? tryKeyItems.find((keyItem) => values?.[0]?.[keyItem])!
          : keyItem
      ];

    const selectedWithTagComponent = values?.map((value: any, index: any) => (
      <div
        className={`pl-[0.25rem] pr-[0.375rem] py-[0.25rem] mr-[0.5rem] bg-n-100 rounded-[0.25rem] typography-caption text-cn-600 text-nowrap flex items-center gap-[0.25rem] ${
          multi && "mb-[0.5rem]"
        }`}
      >
        {selectedComponent(index)}
        <SVGIcon
          iconName="icon-cancel"
          size={16}
          fillColor="var(--cn-500)"
          onClick={(e) => {
            e?.stopPropagation();
            setSearchValue("");
            multi
              ? methods?.removeItem && methods?.removeItem(undefined, value)
              : methods?.clearAll && methods?.clearAll();
            state.search = "";
          }}
        />
      </div>
    ));

    return multi || useSelectedWithTag ? (
      selectedWithTagComponent
    ) : (
      <TooltipContainer
        show
        text={selectedComponent(0)}
        align="left"
        customClass="overflow-hidden text-ellipsis text-nowrap typography-body"
      >
        {useSelectedWithTag ? selectedWithTagComponent : selectedComponent(0)}
      </TooltipContainer>
    );
  };

  const CustomOptionRenderer = ({ item, state, methods }: any) => {
    return (
      <div className="pl-[0.25rem] pr-[0.375rem] py-[0.25rem] mr-[0.5rem] bg-n-100 rounded-[0.25rem] typography-caption text-cn-600 text-nowrap flex items-center gap-[0.25rem]">
        {item?.[keyItem]}
        <SVGIcon
          iconName="icon-cancel"
          size={16}
          fillColor="var(--cn-500)"
          onClick={(e) => {
            e?.stopPropagation();
            setSearchValue("");
            methods?.removeItem && methods?.removeItem(undefined, item);
            state.search = "";
          }}
        />
      </div>
    );
  };

  const width = widthStyle || getWidthFromClass(widthClass);

  const InputStyle = {
    display: "flex",
    alignItems: "center",
    ...(!unit
      ? {
          borderRadius: "0.25rem",
        }
      : {
          borderTopLeftRadius: "0.25rem",
          borderTopRightRadius: "0rem",
          borderBottomLeftRadius: "0.25rem",
          borderBottomRightRadius: "0rem",
        }),
    color: "var(--cn-600)",
    padding:
      (multi && Array.isArray(selected) && selected?.length > 0) ||
      (useSelectedWithTag && !!selected)
        ? "0.375rem 0.625rem"
        : "0.5rem 0.625rem",
    ...(!isFullWidth && width ? { width } : {}),
    ...(useSelectedWithTag || multi ? { minWidth: "fit-content" } : {}),
    ...(disabled
      ? {
          backgroundColor: "var(--n-100)",
          opacity: "1",
          border: "1px solid transparent",
        }
      : { backgroundColor: "var(--n-000)", border: "1px solid var(--cn-300)" }),
    ...customStyle,
  };

  const onKeyDown = ({ event, props, state, methods, setState }: any) => {
    const keyList = dropdownKeyList.filter((key) => key !== "Space");

    onKeyPressDropdownTrigger(
      event,
      currentId ?? "",
      state.dropdown,
      () => setState({ dropdown: true }),
      () => setState({ dropdown: false }),
      disabled ?? false,
      {
        // onSpace: () => onSpace({ props, state, methods, setState }),
        ...(onTab ? { onTab } : {}),
        ...(onShiftTab ? { onShiftTab } : {}),
      },
      keyList
    );
  };

  useEffect(() => {
    if (isSuccess && !isFetching && !isFetchingNextPage) {
      setDropdownDataList &&
        setDropdownDataList(flattenPages(dataQuery?.pages));
    }
  }, [dataQuery, isSuccess, isFetching, isFetchingNextPage]);

  useEffect(() => {
    if (!customDropdownHeader && !addAction) return;
    const newMaxHeight =
      18.75 -
      (dropdownHeaderRect?.height ? pxToRem(dropdownHeaderRect?.height) : 0) -
      (addAction ? 3.25 : 0);
    setOptionsMaxHeight(newMaxHeight);
  }, [dropdownHeaderRect, addAction]);

  const wrapperCN = ctl(`
    ${isHorizontal && "flex"}
    ${isHorizontal && label && "gap-[0.75rem]"}
    ${wrapperCustomClass}
    ${isFullWidth && "w-full"}
  `);

  const labelCN = ctl(`
    text-cn-600
    ${!labelTextCustomClass?.includes("typography") && "typography-caption"}
    ${labelTextCustomClass}
  `);

  const wrapperLabel = ctl(`
    flex items-center gap-[0.25rem] 
    ${isHorizontal ? "h-[2.625rem]" : "h-fit mb-[0.25rem]"}
    ${labelCustomClass}
  `);

  const unitBorderStyle = {
    borderTop: `1px solid ${disabled ? "var(--cn-100)" : "var(--cn-300)"}`,
    borderRight: `1px solid ${disabled ? "var(--cn-100)" : "var(--cn-300)"}`,
    borderBottom: `1px solid ${disabled ? "var(--cn-100)" : "var(--cn-300)"}`,
  };

  useEffect(() => {
    if (!unit) return;

    const newMaxWidth = `calc(100% - ${
      unitRect?.width ? unitRect?.width : 0
    }px)`;
    setMaxWidth(newMaxWidth);
  }, [unitRect]);

  return (
    <div className={wrapperCN}>
      {label && (
        <div className={wrapperLabel}>
          <p className={labelCN} data-testid={`${currentId}-label`}>
            {label}
            {isRequired && <RequiredIcon id={currentId} />}
          </p>
        </div>
      )}

      <div
        className={`flex relative items-center ${
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        } ${isFullWidth ? "react-dropdown-full-width" : ""}`}
        data-testid={currentId}
      >
        <div
          style={{
            maxWidth: unit ? maxWidth : "100%",
          }}
        >
          <Select
            searchBy={keyItem}
            labelField={keyItem}
            valueField="id"
            //@ts-ignore
            values={multi ? selected : selected?.[keyItem] ? [selected] : []}
            options={
              maxOption !== null && maxOption > 0
                ? list?.slice(0, maxOption)
                : list
            }
            //@ts-ignore
            onChange={(values) => _onChange(values)}
            dropdownHandleRenderer={CustomDropdownHandleRenderer}
            clearable={useClearButton && Boolean(selected) && !multi}
            clearRenderer={({ state, methods }) => (
              <SVGIcon
                iconName="icon-cancel"
                size={16}
                fillColor="var(--n-300)"
                onClick={(e) => {
                  e?.stopPropagation();
                  onChange(undefined);
                  setSearchValue("");
                  methods?.clearAll && methods?.clearAll();
                  methods?.dropDown && methods?.dropDown("close");
                  state.search = "";
                }}
                customClass="mr-[0.5rem]"
              />
            )}
            className={customClass}
            style={InputStyle}
            searchFn={onSearch}
            //@ts-ignore
            additionalProps={{ tabIndex, id: currentId }}
            handleKeyDownFn={onKeyDown}
            name={currentId}
            dropdownPosition="auto"
            dropdownRenderer={
              isFetching && !isFetchingNextPage
                ? CustomLoadingDropdownRenderer
                : CustomDropdownRenderer
            }
            disabled={disabled}
            placeholder={placeholder}
            multi={multi}
            onDropdownClose={() => {
              setSearchValue("");
              if (usePortal) enablePageScroll();
            }}
            {...(multi
              ? {
                  optionRenderer: CustomOptionRenderer,
                }
              : {})}
            {...(selected
              ? {
                  contentRenderer: customContent ?? CustomSelectComponent,
                }
              : {})}
            {...(usePortal
              ? {
                  portal: document?.getElementById("shared-dropdown"),
                  onDropdownOpen: () => {
                    setPosition();
                    disablePageScroll();
                  },
                }
              : { dropdownGap: 0 })}
            tryKeyItems={tryKeyItems}
            useSelectedWithTag={useSelectedWithTag}
          />
          {insideElement && insideElement}
        </div>
        {unit && (
          <span
            className="bg-cn-050 px-[0.5rem] py-[0.4375rem] rounded-r-[0.25rem] !w-fit"
            style={unitBorderStyle}
            ref={handleUnitRect}
          >
            <p className="typography-subtitle text-cn-500">{unit}</p>
          </span>
        )}
      </div>
      {errorMessage && showErrorMessage && (
        <p
          className="typography-caption text-r-400 mt-[0.25rem]"
          data-testid={`${currentId}-error-message`}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default SearchDropdown;
export type { ISearchDropdown, ISelectedDropdown };
