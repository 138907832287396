import CenterWrapper from "../design-system/CenterWrapper";

const Error404Fallback = () => {
  return (
    <CenterWrapper>
      <div className="flex flex-col items-center w-full h-full justify-center">
        <img
          src="/assets/image/404.svg"
          alt="not found"
          className="w-[20rem]"
        />
        <p className={`mt-[2.5rem] text-n-700 typography-h2-bold`}>
          404 Not Found
        </p>
      </div>
    </CenterWrapper>
  );
};

export default Error404Fallback;
