import ctl from "@netlify/classnames-template-literals";

import useUser from "../hooks/useUser";
import { shallow } from "zustand/shallow";

const CenterWrapper = ({
  backgroundColor = "white",
  children,
  customClass,
  childCustomClass,
}: {
  backgroundColor?: string;
  children: any;
  customClass?: string;
  childCustomClass?: string;
}) => {
  const { isSidebarMax } = useUser(
    (state) => ({
      isSidebarMax: state.isSidebarMax,
    }),
    shallow
  );

  const WrapperCN = ctl(`
    h-[calc(100vh-2.5rem)]
    ${backgroundColor === "grey" && "bg-n-001"}
    ${backgroundColor === "white" && "bg-n-000"}
    ${backgroundColor === "purple" && "bg-p-400"}
    ${customClass}
  `);

  const SubWrapperCN = ctl(`
    m-auto
    ${window.screen.width <= 1440 && "max-w-[90rem]"}
    ${
      window.screen.width > 1440 &&
      isSidebarMax &&
      "max-w-[calc(100vw-17.75rem)]"
    }
    ${
      window.screen.width > 1440 &&
      !isSidebarMax &&
      "max-w-[calc(100vw-5.375rem)]"
    }
    ${childCustomClass}
  `);

  return (
    <div className={WrapperCN}>
      <div className={SubWrapperCN}>
        <div className="main-body">
          <div className="w-full h-full min-w-[58rem]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CenterWrapper;
