import React, { useEffect, useRef, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import TooltipPortal from "./TooltipPortal";

interface TooltipContainerInterface {
  show: boolean;
  text?: React.ReactNode | React.ReactNode[];
  children?: JSX.Element | React.ReactNode;
  position?: "top" | "bottom";
  align?: "middle" | "left" | "right";
  backgroundClassname?: string;
  useMousePosition?: boolean;
  disableStack?: boolean;
  customClass?: string;
  maxWidth?: string;
}

const TooltipContainer = ({
  show,
  text,
  backgroundClassname = "bg-n-700",
  align = "middle",
  position = "top",
  children,
  useMousePosition,
  disableStack,
  customClass,
  maxWidth = "max-w-[15rem]",
}: TooltipContainerInterface) => {
  const [portalData, setPortalData] = useState<any>({});
  const [clientRect, setClientRect] = useState<HTMLElement | null>();

  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const timeoutId: { current: NodeJS.Timeout | number | null } = useRef(null);
  const tooltipId = uuidv4();

  const setPortal = ({ top, left }: { top?: number; left?: number }) => {
    if (!show) return;

    const rect = clientRect && clientRect?.getBoundingClientRect();

    setIsTooltipShown(true);

    const portalData: any = {
      name: text,
      noWrap: true,
    };

    if (rect) {
      switch (align) {
        case "left":
          portalData.left = rect?.left;
          break;
        case "middle":
          portalData.left = rect?.left + rect?.width / 2;
          portalData.translateX = "-50%";
          break;
        case "right":
          portalData.left = rect?.right;
          portalData.translateX = "-100%";
          break;
      }

      switch (position) {
        case "top":
          portalData.top = rect?.top - 8;
          portalData.translateY = "-100%";
          break;
        case "bottom":
          portalData.top = rect?.bottom + 8;
          break;
      }
    }

    if (useMousePosition) {
      portalData.left = left;
      portalData.top = top;
    }

    setPortalData(portalData);
  };

  const handleRef = (ref: HTMLDivElement) => {
    setClientRect(ref);
  };

  const onMouseEnter = (e: { clientX: any; clientY: any }) => {
    const clientX = e.clientX;
    const clientY = e.clientY;
    timeoutId.current = setTimeout(() => {
      setPortal({ top: clientY, left: clientX });
    }, 500);
  };

  const onMouseLeave = () => {
    setIsTooltipShown(false);
    timeoutId?.current && clearTimeout(timeoutId.current);
  };

  useEffect(() => {
    !show && onMouseLeave();
  }, [show]);

  useEffect(() => {
    if (!disableStack) return;
    const sharedTooltip = document.getElementById(`shared-tooltip`);
    if (!sharedTooltip) return;
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach(function (mutation) {
        const currentTooltip = document.getElementById(tooltipId);
        if (mutation.type !== "childList") return;
        if (sharedTooltip?.children?.length > 1) {
          currentTooltip?.classList.add("hidden");
          return;
        }
        currentTooltip?.classList.remove("hidden");
      });
    });
    observer.observe(sharedTooltip, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [tooltipId, disableStack]);

  const propsElement = {
    ref: (ref: HTMLDivElement) => ref && handleRef(ref),
    onMouseEnter: (e: { clientX: any; clientY: any }) => onMouseEnter(e),
    onMouseLeave,
    onPointerLeave: onMouseLeave,
    className: customClass,
  };

  return (
    <>
      <TooltipPortal
        show={isTooltipShown}
        portalData={portalData}
        portalId="shared-tooltip"
        className={maxWidth}
        tooltipId={tooltipId}
        backgroundClassname={backgroundClassname}
      />
      <div {...propsElement}>{children}</div>
    </>
  );
};

export default TooltipContainer;
